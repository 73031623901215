<template>
  <div class="system-page page">
    <loading :active.sync="isLoading" loader="dots"></loading>
    <div class="card search-card">
      <div class="card-body">
        <form @submit.prevent="searchPerson">
          <div class="form-row">
            
            <div class="col-sm-3 col-md-4">
              <button
                type="submit"
                class="btn btn-primary btn-search"
                style="margin-top: 30px; margin-right: 5px"
                @click="uofSynchronize"
              >
                組織同步
              </button>
              
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="content-wrap">
      <div class="tree-wrap">
        <VueTreeList
          @click="onClick"
          @drop="drop"
          :model="tree"
          v-bind:default-expanded="true"
        >
          <template v-slot:leafNameDisplay="slotProps">
            <div
              class="node"
              :class="{ 'active-node': slotProps.model.id === currentTreeId }"
            >
              <div class="node-content">
                <div style="display: inline-block; vertical-align: top">
                  <div class="department-name">
                    {{ slotProps.model.DepartmentName }}
                  </div>
                  <span class="department-code">{{
                    slotProps.model.DepartmentCode
                  }}</span>
                </div>

                <span
                  class="add-folder"
                  style="margin-left: 5px"
                  @click="addDepartment(slotProps.model)"
                >
                  <eva-icon
                    width="16"
                    height="16"
                    name="plus-outline"
                  ></eva-icon>
                </span>

                <span
                  v-if="slotProps.model.pid !== 0"
                  class="edit-folder"
                  style="margin-left: 5px"
                  @click="editDepartment(slotProps.model)"
                >
                  <eva-icon
                    width="16"
                    height="16"
                    name="edit-outline"
                  ></eva-icon>
                </span>

                <span
                  v-if="
                    slotProps.model.id === currentTreeId &&
                      slotProps.model.pid !== 0 &&
                      slotProps.model.children === null &&
                      rows.length < 1
                  "
                  class="delete-folder"
                  style="margin-left: 5px"
                  @click="onDel(slotProps.model)"
                >
                  <eva-icon
                    width="16"
                    height="16"
                    name="trash-2-outline"
                  ></eva-icon>
                </span>
              </div>
            </div>
          </template>

          <span style="display: none" class="icon" slot="treeNodeIcon">🌲</span>
          <span style="display: none" class="icon" slot="addTreeNodeIcon"
            >📂</span
          >
          <span style="display: none" class="icon" slot="addLeafNodeIcon"
            >＋</span
          >
          <span style="display: none" class="icon" slot="editNodeIcon">📃</span>
          <span style="display: none" class="icon" slot="delNodeIcon">✂️</span>
        </VueTreeList>
      </div>

      <div class="department-wrap">
        <div class="yahome-table-wrap">
          <div style="text-align: right; margin-bottom: 10px"></div>

          <VueBootstrap4Table :rows="rows" :columns="columns" :config="config">
            <!-- 帳號 -->
            <template slot="account" slot-scope="props">
              <button
                class="btn btn-primary"
                type="button"
                data-toggle="modal"
                @click="personModal(false, props.row)"
              >
                {{ props.row.Account }}
              </button>
            </template>

            <!-- 部門主管 -->
            <template slot="IsSupervisor" slot-scope="props">
              <el-switch
                v-model="props.row.IsSupervisor"
                active-color="#13ce66"
                inactive-color="#808080"
                @change="setSupervisor(props.row)"
              >
              </el-switch>
            </template>

            <!-- 刪除 -->
            <template slot="delete" slot-scope="props">
              <button
                type="button"
                class="btn btn-danger btn-delete btn-sm"
                data-toggle="modal"
                data-target="#delPersonModal"
                @click="delModal(props.row)"
              >
                刪除
              </button>
            </template>
          </VueBootstrap4Table>
        </div>
      </div>
    </div>

    <!--新增&編輯部門 Modal-->
    <div
      id="departmentModal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <ValidationObserver
            ref="department"
            class="modal-content"
            v-slot="{ handleSubmit, reset }"
          >
            <form
              @submit.prevent="handleSubmit(saveDepartment)"
              @reset.prevent="reset"
            >
              <div class="modal-header">
                <span v-if="isAdd">新增部門</span>
                <span v-else>編輯部門</span>
              </div>
              <div class="modal-body">
                <div v-if="isAdd" style="margin-bottom: 30px">
                  <h4>
                    <span style="font-size: 16px">上層部門：</span>
                    <span class="badge badge-info">
                      {{ ParentDepartmentName }}
                    </span>
                  </h4>
                </div>
                <div class="form-group">
                  <ValidationProvider
                    tag="div"
                    rules="required"
                    v-slot="{ errors }"
                    name="部門名稱"
                  >
                    <label for="departmentName"
                      ><span class="mark">*</span>部門名稱</label
                    >
                    <input
                      type="text"
                      id="departmentName"
                      class="form-control"
                      @compositionstart="is_composing = true"
                      @compositionend="is_composing = false"
                      v-model="department.DepartmentName"
                    />
                    <span v-if="errors[0] && !is_composing" class="text-danger"
                      >{{ errors[0] }}
                    </span>
                  </ValidationProvider>
                </div>
                <div class="form-group">
                  <ValidationProvider
                    tag="div"
                    :rules="`my_not:${nowItem.DepartmentCode}|required`"
                    v-slot="{ errors }"
                    name="部門代號"
                  >
                    <label for="departmentCode"
                      ><span class="mark">*</span>部門代號<span class="mark"
                        >(代號不可重複)</span
                      >
                    </label>
                    <input
                      type="text"
                      id="departmentCode"
                      class="form-control"
                      v-model="department.DepartmentCode"
                    />
                    <span v-if="errors[0]" class="text-danger"
                      >{{ errors[0] }}
                    </span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="reset"
                  class="btn btn-secondary"
                  aria-label="關閉"
                  data-dismiss="modal"
                >
                  取消
                </button>
                <button type="submit" class="btn btn-success" aria-label="儲存">
                  儲存
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>

    <!--刪除部門 Modal-->
    <div
      id="delDepartmentModal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <span>刪除部門</span>
            <!-- <button
              type="button"
              class="close"
              aria-label="關閉"
              data-dismiss="modal"
            >
              <span style="color: #000">&times;</span>
            </button> -->
          </div>
          <div class="modal-body">
            確認刪除<span style="font-weight: bolder; color: red">
              {{ department.DepartmentName }}</span
            >
            ?
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              aria-label="關閉"
              data-dismiss="modal"
            >
              取消
            </button>
            <button type="button" class="btn btn-danger" @click="delDepartment">
              刪除
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--新增&編輯人員 Modal -->
    <div
      class="modal fade"
      id="personModal"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <ValidationObserver
          ref="person"
          class="modal-content"
          tag="div"
          v-slot="{ handleSubmit, reset }"
        >
          <form
            @submit.prevent="handleSubmit(savePerson)"
            @reset.prevent="reset"
          >
            <div class="modal-header">
              <span v-if="isNew">新增人員</span>
              <span v-else>編輯人員</span>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <ValidationProvider
                  tag="section"
                  rules="required"
                  v-slot="{ errors }"
                  name="帳號"
                >
                  <label><span class="mark">*</span>帳號</label>
                  <input
                    type="type"
                    name="name"
                    class="form-control"
                    :disabled="!isNew"
                    v-model="person.Account"
                  />
                  <span class="text-danger">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <ValidationProvider
                  tag="section"
                  rules="required"
                  v-slot="{ errors }"
                  name="姓名"
                >
                  <label><span class="mark">*</span>姓名</label>
                  <input
                    type="type"
                    name="name"
                    class="form-control"
                    @compositionstart="is_composing = true"
                    @compositionend="is_composing = false"
                    v-model="person.UserName"
                  />
                  <span v-if="errors[0] && !is_composing" class="text-danger"
                    >{{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <ValidationProvider
                  tag="section"
                  rules="required|email"
                  v-slot="{ errors }"
                  name="Email"
                >
                  <label><span class="mark">*</span>Email</label>
                  <input
                    type="type"
                    name="name"
                    class="form-control"
                    v-model="person.Email"
                  />
                  <span class="text-danger">{{ errors[0] }} </span>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <ValidationProvider
                  tag="section"
                  rules="required"
                  v-slot="{ errors }"
                  name="電話號碼"
                >
                  <label><span class="mark">*</span>電話號碼</label>
                  <input
                    type="type"
                    name="name"
                    class="form-control"
                    v-model="person.PhoneNumber"
                  />
                  <span class="text-danger">{{ errors[0] }} </span>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <ValidationProvider
                  tag="section"
                  name="主要部門"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label><span class="mark">*</span>主要部門</label>
                  <treeselect
                    :options="departmentOptions"
                    placeholder="請選擇"
                    v-model="mainDepartment.DepartmentGuid"
                  />

                  <span class="text-danger">{{ errors[0] }} </span>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <label><span class="mark">*</span>是否為主要部門主管</label>
                <ValidationProvider
                  tag="section"
                  name="是否為部門主管"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <select
                    class="form-control"
                    v-model="mainDepartment.IsSupervisor"
                  >
                    <option :value="null" disabled>請選擇</option>
                    <option :value="true">是</option>
                    <option :value="false">否</option>
                  </select>
                  <span class="text-danger">{{ errors[0] }} </span>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <hr />
                <div
                  v-for="(item, index) in othersDepartment"
                  :key="index"
                  style="
                    margin-bottom: 20px;
                    display: flex;
                    justify-content: space-between;
                        font-size: 0.9em;
                  "
                >
                  <div style="flex:1;">
                    兼任部門<span
                      style="
                        background-color: rgb(236 241 255);
                        border-radius: 5px;
                        padding: 5px 10px;
                      "
                      >{{ item.DepartmentName }}</span
                    >
                  </div>
                  <div style="margin-right:10px;">
                    是否為此部門主管<span
                      style="
                        background-color: rgb(236 241 255);
                        border-radius: 5px;
                        padding: 5px 10px;
                      "
                      >{{ item.IsSupervisor ? "是" : "否" }}</span
                    >
                  </div>

                  <button
                    type="button"
                    class="btn btn-danger btn-sm"
                    @click="delItem(index)"
                  >
                    <eva-icon fill="#FFF" name="trash-2-outline"></eva-icon>
                  </button>
                </div>
                <div>
                  <AddDepartment
                    v-if="addOthersDepartment"
                    @addDepartment="saveData"
                    @addCancel="cancelItem"
                  />
                  <button
                    v-if="!addOthersDepartment"
                    type="button"
                    class="btn btn-primary btn-sm"
                    @click="addItem"
                  >
                    <i class="fas fa-plus"></i> 新增兼任部門
                  </button>
                </div>
              </div>

              <div class="form-group">
                <label>角色</label>
                <treeselect
                  :multiple="true"
                  :options="roleOptions"
                  placeholder="請選擇"
                  valueFormat="object"
                  v-model="Roles"
                />
              </div>
            </div>
            <div class="modal-footer">
              <button
                v-if="!isNew"
                type="button"
                class="btn btn-warning"
                @click="changePassword"
              >
                重設密碼
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                aria-label="關閉"
                data-dismiss="modal"
              >
                取消
              </button>
              <button type="submit" class="btn btn-success">儲存</button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>

    <!--刪除人員 Modal-->
    <div
      class="modal fade"
      id="delPersonModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <span>刪除人員</span>
          </div>
          <div class="modal-body">
            確認刪除
            <span style="font-weight: bolder; color: red">
              {{ person.Name }}
            </span>
            ?
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              aria-label="關閉"
              data-dismiss="modal"
            >
              取消
            </button>
            <button
              type="button"
              class="btn btn-danger"
              data-dismiss="modal"
              @click="delPerson"
            >
              刪除
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* global $ */
import { VueTreeList, Tree, TreeNode } from "vue-tree-list";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

import AddDepartment from "@/components/back/AddDepartment.vue";
import { Notification } from "element-ui";

export default {
  name: "Department",
  data() {
    return {
      is_composing: false, // compositionstart& compositionend

      isAdd: true, // 新增部門

      isClick: false, // 是否選取部門

      //查詢人員
      keyword: null,

      // 人員 新增&編輯
      isNew: false,
      // 顯示部門
      departmentOptions: [],
      // 顯示角色
      roleOptions: [],
      // 選取主要部門
      mainDepartment: {},
      // 選取兼任部門
      addOthersDepartment: false,
      othersDepartment: [],
      // 選取角色
      Roles: [],
      // data
      person: {},

      // 被點擊的treeID
      currentTreeId: "",

      // 部門(Tree)
      tree: new Tree([]), // 部門
      isRoot: false,
      rootGuid: null, // 主部門Guid
      departmentGuid: null, // 當前部門Guid
      ParentDepartmentName: null, // 上層部門名稱
      nowItem: {}, // 判斷部門代號是否重複
      department: {}, // 部門Data

      // 人員 table
      rows: [],
      columns: [
        {
          label: "帳號",
          name: "Account",
          slot_name: "account",
        },
        {
          label: "姓名",
          name: "Name",
        },
        {
          label: "Email",
          name: "Email",
        },
        {
          label: "電話號碼",
          name: "PhoneNumber",
        },
        {
          label:"主管職",
          name: "IsSupervisor",
          slot_name: "IsSupervisor",
        },
        {
          label: "",
          name: "delete",
          slot_name: "delete",
        },
      ],

      // person-table style
      config: {
        global_search: {
          visibility: false,
        },

        show_refresh_button: false,
        show_reset_button: false,

        pagination: true,
        pagination_info: false,
        num_of_visibile_pagination_buttons: 5,
        per_page: 10,
        per_page_options: [5, 10, 20],
        selected_rows_info: false,

        highlight_row_hover: false,
        rows_selectable: true,
        multi_column_sort: false,
        card_mode: false,
      },
    };
  },
  components: {
    VueTreeList,
    Treeselect,
    AddDepartment,
  },
  methods: {
    ///////////////////////////  取得部門 API  ///////////////////////////////////////////
    getDepartment() {
      const vm = this;
      const api = `${process.env.VUE_APP_API}/BasicInformation/department`;

      vm.$store.dispatch("updateLoading", true);
      vm.$http
        .get(api)
        .then((response) => {
          if (response.status === 200) {
            vm.$store.dispatch("updateLoading", false);
            vm.departmentOptions = response.data;
            vm.tree = new Tree([
              {
                DepartmentGuid: response.data[0].DepartmentGuid,
                DepartmentCode: response.data[0].DepartmentCode,
                DepartmentName: response.data[0].DepartmentName,
                ParentDepartmentGuid: response.data[0].ParentDepartmentGuid,
                delNodeDisabled: true,
                dragDisabled: true,
                children: response.data[0].children,
              },
            ]);
            vm.rootGuid = response.data[0].DepartmentGuid;
          }
        })
        .catch((error) => {
          vm.$store.dispatch("updateLoading", false);
          vm.$bus.$emit("message: push", error.response.data, "danger");
        });
    },

    ///////////////////////////  取得角色 API  ///////////////////////////////////////////
    getRole() {
      const vm = this;
      const api = `${process.env.VUE_APP_API}/auth/role`;
      const params = {
        name: null,
      };

      vm.$http.get(api, { params }).then((response) => {
        if (response.status === 200) {
          vm.roleOptions = [];
          response.data.forEach((item) => {
            let role = {
              id: item.Id,
              label: item.Name,
            };
            vm.roleOptions.push(role);
          });
        }
      });
    },

    ///////////////////////////  查詢人員 API  ///////////////////////////////////////////
    searchPerson() {
      const vm = this;
      const api = `${process.env.VUE_APP_API}/auth/backgrounduser`;
      const params = {
        keyword: vm.keyword,
      };

      vm.$http.get(api, { params }).then((response) => {
        if (response.status === 200) {
          vm.rows = response.data;
        }
      });
    },

    ///////////////////////////  人員 新增&編輯 Modal ///////////////////////////////////////////
    personModal(isNew, item) {
      if (!isNew) {
        this.person = {
          Account: item.Account,
          UserName: item.Name,
          Email: item.Email,
          PhoneNumber: item.PhoneNumber,
        };
        this.getPersonDepartment(item.Account);
        this.isNew = false;
      } else {
        this.person = {};
        this.mainDepartment = {
          Seq: 0,
          DepartmentGuid: null,
          IsSupervisor: null,
        };
        this.othersDepartment = [];
        this.isNew = true;
      }
      this.$refs.person.reset();
    },

    addNode() {
      var node = new TreeNode({ name: "new node", isLeaf: false });
      if (!this.data.children) this.data.children = [];
      this.data.addChildren(node);
    },

    // 取得人員歸屬部門
    getPersonDepartment(item) {
      const vm = this;
      const account = item;
      const api = `${process.env.VUE_APP_API}/auth/backgrounduser/department/${account}`;

      vm.$http
        .get(api)
        .then((response) => {
          if (response.status === 200) {
            vm.getPersonRole(account);
            vm.othersDepartment = [];
            response.data.forEach((item) => {
              if (item.Seq === 0) {
                vm.mainDepartment = {
                  DepartmentGuid: item.DepartmentGuid,
                  IsSupervisor: item.IsSupervisor,
                  Seq: item.Seq,
                };
              } else {
                vm.othersDepartment.push(item);
              }
            });
          }
        })
        .catch((error) => {
          vm.$bus.$emit("message: push", error.response.data, "danger");
        });
    },

    //取得人員擁有角色
    getPersonRole(item) {
      const vm = this;
      const api = `${process.env.VUE_APP_API}/auth/backgrounduser/roles/${item}`;

      vm.$http
        .get(api)
        .then((response) => {
          if (response.status === 200) {
            
            vm.Roles = response.data;
            $("#personModal").modal("show");
          }
        })
        .catch((error) => {
          vm.$bus.$emit("message: push", error.response.data, "danger");
        });
    },

    // 新增兼任部門
    addItem() {
      this.addOthersDepartment = true;
    },
    // 取消新增兼任部門
    cancelItem() {
      this.addOthersDepartment = false;
    },
    // 刪除兼任部門
    delItem(id) {
      this.othersDepartment.splice(id, 1);
    },
    // 儲存兼任部門
    saveData(item) {
      this.addOthersDepartment = false;
      this.othersDepartment.push({ ...item });
    },

    ///////////////////////////  人員 新增&編輯 API ///////////////////////////////////////////
    savePerson() {
      const vm = this;

      let api = `${process.env.VUE_APP_API}/auth/backgrounduser`;
      let httpMethod = "post";
      let message = "新增成功";

      if (!vm.isNew) {
        api = `${process.env.VUE_APP_API}/auth/backgrounduser`;
        httpMethod = "put";
        message = "編輯成功";
      }

      // 兼任部門
      let othersDepartment = [];
      if (vm.othersDepartment.length > 0) {
        vm.othersDepartment.map((item, index) => {
          let data = {
            Seq: ++index,
            DepartmentGuid: item.DepartmentGuid,
            IsSupervisor: item.IsSupervisor,
          };
          othersDepartment.push(data);
        });
      }

      let data = {
        ...vm.person,
        Departments: [vm.mainDepartment, ...othersDepartment],
        Roles: vm.Roles,
      };

      

      $("#personModal").modal("hide");
      vm.$store.dispatch("updateLoading", true);
      vm.$http[httpMethod](api, data)
        .then((response) => {
          if (response.status === 200) {
            vm.$store.dispatch("updateLoading", false);
            vm.$bus.$emit("message: push", message);
            vm.currentTreeId = vm.mainDepartment.DepartmentGuid;
            vm.departmentGuid = vm.mainDepartment.DepartmentGuid;
            vm.getPerson();
          }
        })
        .catch((error) => {
          vm.$store.dispatch("updateLoading", false);
          vm.$bus.$emit("message: push", error.response.data, "danger");
        });
    },

    ///////////////////////   設定主管 API  //////////////////////////////////
    setSupervisor(item) {
      const vm = this;
      const api = `${process.env.VUE_APP_API}/BasicInformation/department/setsupervisor`;

      let message = "設為主管職";
      let color = "success";
      if (!item.IsSupervisor) {
        message = "移除主管職";
        color = "secondary";
      }
      const data = {
        UserId: item.UserId,
        DepartmentGuid: item.DepartmentGuid,
        IsSupervisor: item.IsSupervisor,
      };

      vm.$store.dispatch("updateLoading", true);
      vm.$http
        .post(api, data)
        .then((response) => {
          if (response.status === 200) {
            vm.$store.dispatch("updateLoading", false);
            vm.$bus.$emit("message: push", message, color);
            vm.getPerson();
          }
        })
        .catch((error) => {
          vm.$store.dispatch("updateLoading", false);
          vm.$bus.$emit("message: push", error.response.data, "danger");
        });
    },

    ///////////////////////////////   部門  /////////////////////////////////////

    // 新增部門 Modal
    addDepartment(params) {
      this.isAdd = true;
      this.ParentDepartmentName = params.DepartmentName;
      this.department = {
        ParentDepartmentGuid: params.DepartmentGuid,
      };
      this.$refs.department.reset();
      $("#departmentModal").modal("show");
    },

    // 編輯部門 Modal
    editDepartment(item) {
      this.isAdd = false;
      this.department = {
        DepartmentGuid: item.DepartmentGuid,
        DepartmentCode: item.DepartmentCode,
        DepartmentName: item.DepartmentName,
        ParentDepartmentGuid: item.ParentDepartmentGuid,
      };
      this.nowItem = {
        // 原部門 data
        DepartmentGuid: item.DepartmentGuid,
        DepartmentCode: item.DepartmentCode,
        DepartmentName: item.DepartmentName,
        ParentDepartmentGuid: item.ParentDepartmentGuid,
      };
      if (item.pid === 0) {
        this.department.ParentDepartmentGuid = null;
      }
      this.$refs.department.reset();
      $("#departmentModal").modal("show");
    },

    ///////////////////////////   部門 新增&編輯 API  /////////////////////////////////////////
    saveDepartment() {
      const vm = this;
      const api = `${process.env.VUE_APP_API}/BasicInformation/department`;

      let httpMethod = "post";
      let message = "新增成功";

      if (!vm.isAdd) {
        httpMethod = "put";
        message = "編輯成功";
      }

      if (vm.isRoot) {
        httpMethod = "post";
        message = "編輯成功";
      }

      const data = { ...vm.department };
      vm.isRoot = false;
      $("#departmentModal").modal("hide");
      vm.$store.dispatch("updateLoading", true);

      vm.$http[httpMethod](api, data)
        .then((response) => {
          if (response.status === 200) {
            vm.$store.dispatch("updateLoading", false);
            vm.$bus.$emit("message: push", message);
            vm.getDepartment();
          }
        })
        .catch((error) => {
          vm.$store.dispatch("updateLoading", false);
          vm.$bus.$emit("message: push", error.response.data, "danger");
        });
    },

    ///////////////////////////  拖拉部門 API  /////////////////////////////////////////
    drop(e) {
      
      if (e.node === e.target) {
        return;
      }
      const vm = this;
      const api = `${process.env.VUE_APP_API}/BasicInformation/department`;

      const data = {
        DepartmentGuid: e.node.DepartmentGuid,
        DepartmentCode: e.node.DepartmentCode,
        DepartmentName: e.node.DepartmentName,
        ParentDepartmentGuid: e.target.DepartmentGuid,
      };

      vm.$http
        .put(api, data)
        .then((response) => {
          if (response.status === 200) {
            vm.$bus.$emit("message: push", "部門移動成功");
            vm.getDepartment();
          }
        })
        .catch((error) => {
          vm.$bus.$emit("message: push", error.response.data, "danger");
        });
    },

    // 刪除部門 Modal
    onDel(node) {
      if (node.children === null) {
        this.department = {
          DepartmentGuid: node.DepartmentGuid,
          DepartmentName: node.DepartmentName,
        };
        $("#delDepartmentModal").modal("show");
      }
    },

    // 刪除部門 API
    delDepartment() {
      const vm = this;
      const DepartmentGuid = vm.department.DepartmentGuid;
      const api = `${process.env.VUE_APP_API}/BasicInformation/department/${DepartmentGuid}`;

      $("#delDepartmentModal").modal("hide");
      vm.$store.dispatch("updateLoading", true);
      vm.$http
        .delete(api)
        .then((response) => {
          if (response.status === 200) {
            vm.$store.dispatch("updateLoading", false);
            vm.$bus.$emit("message: push", "刪除成功");
            vm.getDepartment();
          }
        })
        .catch((error) => {
          vm.$store.dispatch("updateLoading", false);
          vm.$bus.$emit("message: push", error.response.data, "danger");
        });
    },

    // 點擊部門
    onClick(node) {
      this.isClick = true; // 選取部門
      this.currentTreeId = node.id;
      this.departmentGuid = node.DepartmentGuid;
      this.DepartmentName = node.DepartmentName;
      this.getPerson();
    },

    // 取得當前部門內人員
    getPerson() {
      const vm = this;
      const api = `${process.env.VUE_APP_API}/BasicInformation/department/user/${vm.departmentGuid}`;

      // vm.showPerson = [];
      // vm.filterData = [];
      // vm.personList = [];

      vm.$http
        .get(api)
        .then((response) => {
          if (response.status === 200) {
            vm.rows = response.data;
            // 顯示的人員
            // vm.rows.forEach((item) => {
            //   vm.showPerson.push(item.Name);
            // });
            // // filter data
            // vm.rows.forEach((item) => {
            //   vm.filterData.push(item.Name + "-" + item.UserName);
            // });
            // // 人員的資料
            // vm.rows.forEach((item, index) => {
            //   vm.personList.push({
            //     label: item.Name,
            //     key: item.UserId,
            //     filterData: vm.filterData[index],
            //   });
            // });
          }
        })
        .catch((error) => {
          vm.$bus.$emit("message: push", error.response.data, "danger");
        });
    },

    // 重設密碼 API
    changePassword() {
      const vm = this;
      const api = `${process.env.VUE_APP_API}/auth/user/ChangePasswordByAdmin/${vm.person.Account}`;

      vm.$store.dispatch("updateLoading", true);
      vm.$http
        .post(api)
        .then((response) => {
          if (response.status === 200) {
            vm.$store.dispatch("updateLoading", false);
            vm.$bus.$emit("message: push", "已寄送變更密碼郵件");
          }
        })
        .catch((error) => {
          vm.$store.dispatch("updateLoading", false);
          vm.$bus.$emit("message: push", error.response.data, "danger");
        });
    },

    /////////////////////////////  刪除人員  /////////////////////////////////////

    // 刪除人員 Modal
    delModal(item) {
      
      this.person = {
        Name: item.Name,
        UserName: item.UserName,
      };
    },

    // 刪除部門內人員 API
    delPerson() {
      const vm = this;
      const api = `${process.env.VUE_APP_API}/auth/backgrounduser/${vm.person.UserName}`;

      $("#delPersonModal").modal("hide");
      vm.$store.dispatch("updateLoading", true);
      vm.$http
        .delete(api)
        .then((response) => {
          if (response.status === 200) {
            vm.$store.dispatch("updateLoading", false);
            vm.$bus.$emit("message: push", "刪除成功");
            vm.getPerson();
          }
        })
        .catch((error) => {
          vm.$store.dispatch("updateLoading", false);
          vm.$bus.$emit("message: push", error.response.data, "danger");
        });
    },


    uofSynchronize(){
      const vm = this;
      const api = `${process.env.VUE_APP_API}/BasicInformation/syncfromuof`;

      vm.$store.dispatch("updateLoading", true);
      vm.$http
        .post(api)
        .then((response) => {
          if (response.status === 200) {
            vm.$store.dispatch("updateLoading", false);
            Notification({
              title: "成功",
              message: "同步成功",
              type: "success",
              duration: 2000,
            });
          }
        })
        .catch((error) => {
          vm.$store.dispatch("updateLoading", false);
          Notification({
            title: "錯誤",
            message: error.response.data,
            type: "error",
            duration: 2000,
          });
        });
    }
  },

  computed: {
    isLoading() {
      return this.$store.state.isLoading;
    },
  },

  created() {
    this.getDepartment();
    this.getRole();
  },
};
</script>

<style lang="scss" scoped>
.vtl {
  .vtl-drag-disabled {
    background-color: #d0cfcf;
    &:hover {
      background-color: #d0cfcf;
    }
  }
  .vtl-disabled {
    background-color: #d0cfcf;
  }
}
.vtl-tree-node {
  cursor: move;
}
.vtl-node-main {
  padding: 0;
  transition: all 0.2s !important;
  &:hover {
    background-color: transparent !important;
  }
}

.vtl .vtl-drag-disabled {
  background-color: transparent;
}

.vtl-border.vtl-up {
  margin-top: 0px;
}

.vtl-border {
  height: 0;
}

.department-name {
  font-size: 1.1em;
}
.department-code {
  font-size: 0.9em;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  padding: 0 10px;
}

.department-edit-card {
  padding: 20px;
  text-align: right;
  background-color: #fff;
  border-radius: 5px;
  padding-bottom: 0;
  input {
    background-color: #f4f6ff;
    border: none;
    line-height: 34px;
    padding: 0 15px;
    margin-right: 10px;
    vertical-align: middle;
    border-radius: 5px;
  }
}

.content-wrap {
  display: flex;
  height: calc(100vh - 160px);
  .tree-wrap {
    margin-right: 10px;
    background-color: #fff;
    padding: 30px;
    border-radius: 5px;
    width: 500px;
    overflow: auto;

    & > .vtl {
      border: none;
    }

    .edit-folder,
    .add-folder {
      background: #d7e6ff;
      display: inline-block;
      width: 25px;
      height: 25px;
      line-height: 25px;
      text-align: center;
      border-radius: 50%;
      cursor: pointer;
      i {
        opacity: 0.7;
      }
    }
    .delete-folder {
      background: #f77373;
      display: inline-block;
      width: 25px;
      height: 25px;
      line-height: 25px;
      text-align: center;
      border-radius: 3px;
      margin-left: 3px;
      border-radius: 50%;
      cursor: pointer;
      opacity: 0.7;
    }
  }

  .department-wrap {
    flex: 1;
    min-width: 1px;
    background-color: #fff;
  }
}

.node {
  padding: 5px;
  font-size: 0.8em;
  white-space: nowrap;

  .node-content {
    padding: 5px;
    border-left: #fff 5px solid;
  }

  &.active-node {
    .node-content {
      background-color: #f7f7f7;
      border-left: #303c50 5px solid;
      display: block;
    }

    .department-code {
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
}
</style>
